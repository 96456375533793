* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.faqs {
	width: 100%;
	margin: 0 auto;
	color: #737373;
	font-size: 18px;
}

.faqs .faq {
	margin: 15px 0;
	padding: 25px;
	background-color: #FFF;
	border-radius: 8px;
}

.arrow::before {
	display: inline-block;
	content: '';
	right: 0;
	width: 15px;
	height: 15px;

	background-image: url('./img/arrow.png');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.faqs .faq .faq-question {
	position: relative;
	cursor: pointer;
	line-height: 22px;
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
	font-size: 16px;
	padding-left: 24px;
	font-weight: 100;
	white-space: pre-line;
}

.faqs .faq.open .faq-question {
	margin-bottom: 20px;
}

.faqs .faq.open .arrow::before {
	transform: rotate(90deg);
}

.faqs .faq.open .faq-answer {
	max-height: 1300px;
	opacity: 1;
}
