/* ----------------------------------------------
 * Generated by Animista on 2021-1-22 16:38:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes slide-top {
    0% { transform: translateY(120px) }
    100% { transform: translateY(0) }
}

.slide-top {
    animation: slide-top 0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-28 10:33:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes slide-bottom {
    0% { transform: translateY(0) }
    100% { transform: translateY(120px) }
}

.slide-bottom {
    animation: slide-bottom 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
